import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import HeroSimple from "../components/hero/heroSimple/heroSimple"
import Wrapper from "../components/layout/wrapper/wrapper"
import BlockContent from "../components/blockContent/blockContent"
import Seo from "../components/seo/seo"

import Thumbnail from "../components/shared/thumbnail/thumbnail"
import SidebarForm from "../components/shared/sidebarForm/sidebarForm"
import Cta from "../components/cta/cta"
import CtaForm from "../components/ctaForm/ctaForm"

// todo
import styles from "./shared.module.scss"

const Resource = ({ data, pageContext }) => {
  const { content: resource, seo } = data.sanityResource.tabs

  const steps = [
    {
      link: `resources`,
      title: "Resources",
    },
    {
      link: `resources/${resource.category.slug.current}`,
      title: resource.category.title,
    },
  ]

  return (
    <Layout>
      <Seo
        isBlogPost={false}
        title={resource.title}
        path={pageContext.path}
        image={resource.featuredImage?.asset.url}
        breadcrumbs={steps}
        {...seo}
      />
      <Breadcrumbs steps={steps} />
      <HeroSimple blocks={resource.hero[0].children} subtitle={resource.subtitle} />
      <Wrapper noPadding>
        <div className={styles.inner}>
          {resource.form && !(resource.form.link == null) ? (
            <aside className="grid">
              <div className="gridItem small-12 medium-3 medium-offset-1">
                <Thumbnail image={resource.featuredImage?.asset.fluid} noBorder />
                <SidebarForm link={resource.form.link} title={resource.form.title} />
              </div>
              <div className="gridItem small-12 medium-6 medium-offset-5">
                <BlockContent blocks={resource.richText} />
              </div>
            </aside>
          ) : (
            <aside className="grid">
              <div className="gridItem small-12 medium-10 medium-offset-1">
                <BlockContent blocks={resource.richText} />
              </div>
            </aside>
          )}
        </div>
      </Wrapper>
      {resource.cta &&
        (resource.cta?.__typename === "SanityCtaForm" ? (
          <CtaForm {...resource.cta} background="blue" />
        ) : (
          <Cta {...resource.cta} background="blue" />
        ))}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    sanityResource(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      tabs {
        content {
          hero: _rawHero
          subtitle
          richText: _rawRichText(resolveReferences: { maxDepth: 5 })
          form {
            link
            title
          }
          title
          subtitle
          category {
            title
            slug {
              current
            }
          }
          slug {
            current
          }
          featuredImage {
            asset {
              fluid(maxWidth: 580) {
                ...GatsbySanityImageFluid
              }
            }
          }
          cta {
            ... on SanityCta {
              ...ctaFragment
            }
            ... on SanityCtaForm {
              ...ctaFormFragment
            }
          }
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
  }
`

export default Resource
