import React from "react"
import Img from "gatsby-image"
import cx from "classnames"

import styles from "./thumbnail.module.scss"

const Thumbnail = ({ image, date, author, noBorder }) => {
  return (
    <div className={cx(styles.thumbnail, noBorder ? styles.noBorder : "")}>
      <Img className={styles.image} fluid={image} />
      {author ? <p className={styles.author}>by {author}</p> : null}
      <p>{date}</p>
    </div>
  )
}

export default Thumbnail
